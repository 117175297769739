/**
 * @file src/web-app/src/components/Shared/HoverContent.tsx
 * @description This file contains the HoverContent React component: It is used to display the module/section example.
 * 
 * Author: Soham Dave
 * Date: 2024-10-03
 */

import { Box, Typography } from "@mui/material";
import { NoteSectionDisplayBox } from "../Note/NoteSectionDisplayBox";

// New HoverContent component
interface HoverContentProps {
    title: string;
    content: string;
  }
  export const HoverContent = ({ title, content }: HoverContentProps) => (
    <Box
      className="hover-content"
      sx={{
        position: "absolute",
        left: 0,
        width: "100%",
        opacity: 0,
        visibility: "hidden",
        transition: "opacity 0.2s, visibility 0.2s",
        zIndex: 1,
        backgroundColor: "white",
        boxShadow: "0 0.8vh 0.8vh rgba(0, 0, 0, 0.5)",
        borderRadius: "1.5vh",
        border: "0.15vh solid",
        borderColor: "divider",
        padding: "0.8vh",
        pointerEvents: "none",
      }}
    >
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <NoteSectionDisplayBox fontSize={10}>
        {content}
    </NoteSectionDisplayBox>
    </Box>
  );
  