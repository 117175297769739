import React from "react";
import { Card, CardContent, SxProps, Theme, Typography } from "@mui/material";

interface ContentCardProps {
  content: React.ReactNode;
  sx?: SxProps<Theme>;
  icon: React.ReactNode;
  title: string;
}

export const ContentCard: React.FC<ContentCardProps> = ({
  content,
  sx = {},
  icon,
  title,
}) => {
  return (
    <Card
      sx={{
        borderRadius: 3,
        bgcolor: "backgroundColors.primary",
        borderColor: "borderColors.secondary",
        border: "0.5px solid",
        boxShadow: "none",
        ...sx,
      }}
    >
      <CardContent sx={{ pb: 0 }}>
        <>
          <Typography
            variant="body1"
            sx={{
              fontWeight: "bold",
              color: "newDesignColors.eggplant700",
              display: "flex",
              alignItems: "center",
              mb: 2,
            }}
          >
            {icon}
            {title}
          </Typography>
          {content}
        </>
      </CardContent>
    </Card>
  );
};
