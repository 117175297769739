import {
  Box,
  Card,
  CardContent,
  LinearProgress,
  Tooltip,
  Typography,
  Button,
} from "@mui/material";
import Logo from "../Icons/Logo";
import {
  AvailableModule,
} from "../../types/types";
import { HoverContent } from "../Shared/HoverContent";
import { AccountCircle } from "@mui/icons-material";
import { useThemeContext } from "../../context/theme/context";

interface NewModuleBlockProps {
  module: AvailableModule;
  handleClick?: () => void;
}

const NewModuleBlock = ({ module, handleClick }: NewModuleBlockProps) => {
  const { brandLanguage } = useThemeContext();
  return (
    <Card
      sx={{
        margin: 1,
        borderColor: "borderColors.primary",
        border: "1px solid",
        "&:hover": {
          boxShadow: 3, // Adjust shadow on hover
        },
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              fontStyle="normal"
              fontWeight="700"
              sx={{
                cursor: "pointer",
                "&:hover + .hover-content": {
                  cursor: "pointer",
                  display: "block",
                  opacity: 1,
                  visibility: "visible",
                },
              }}
            >
              {module.name}
            </Typography>
            {module?.example && (
              <HoverContent
                title={module.name ?? ""}
                content={module?.example || "No example provided."}
              />
            )}
            <Typography fontSize=".7rem">{module.description}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "flex-end",
              height: "100%",
              ml: 2,
            }}
          >
            {module.is_custom_user_module ? (
              <Tooltip
                title="This is your customized section."
                placement="top"
                arrow
              >
                <AccountCircle sx={{ mb: 1 }} />
              </Tooltip>
            ) : (
              <Tooltip
                title={`This is a ${brandLanguage.brandName} section.`}
                placement="top"
                arrow
              >
                <Box sx={{ mb: 1 }}>
                  <Logo size={22} />
                </Box>
              </Tooltip>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={handleClick}
              sx={{
                borderRadius: "1vh",
                padding: "0.5vh 1.5vh",
              }}
            >
              Add
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
export default NewModuleBlock;
