import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Tooltip,
  IconButton,
  TextField,
  CircularProgress,
  useTheme,
  Alert,
  Button,
  Modal,
} from "@mui/material";
import {
  Paid,
  InfoOutlined,
  Edit as EditIcon,
  Check as CheckIcon,
  MoneyOutlined,
} from "@mui/icons-material";
import { ContentCard } from "../Views/Shared/ContentCard";
import { ConditionalTooltip } from "./ConditionalTooltip";
import { RoundedButton } from "../styles/CustomButtons";
import { GreenBubble, YellowBubble, RedBubble } from "./Icons/ColoredBubbles";
import { BillingCodeExtraData, NoteExtraType, Patient } from "../types/types";
import { UserState } from "../context/user/context";
import { styled } from "@mui/material/styles";
import { Tooltip as MuiTooltip } from "@mui/material";

// Define a styled component for the receipt card
const ReceiptCard = styled(Box)<{ maxHeight?: string }>(
  ({ theme, maxHeight }) => ({
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1), // Reduced padding
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    fontSize: "1rem", // Smaller font size
    lineHeight: 1.1, // More condensed line height
    maxHeight: maxHeight || "30vh", // Set max height with default value
    overflow: "hidden", // Hide overflow
    position: "relative", // Position relative for overlay
  })
);

interface BillingSidebarProps {
  billingCodeExtra: { status: string; data: BillingCodeExtraData };
  patientData: Patient | null | undefined;
  userState: UserState;
  showTooltips: boolean;
  billingSubmitted: boolean;
  handleProviderInfoClick: () => void;
  handlePatientInfoClick: () => void;
  handlePatientEligibilityClick: () => void;
  handleOpenBillingSidePane: () => void;
  handleAssignPatientClick: () => void;
  handleResubmitClaim: () => void;
}

const BillingSidebar: React.FC<BillingSidebarProps> = ({
  billingCodeExtra,
  patientData,
  userState,
  showTooltips,
  billingSubmitted,
  handleProviderInfoClick,
  handlePatientInfoClick,
  handlePatientEligibilityClick,
  handleOpenBillingSidePane,
  handleAssignPatientClick,
  handleResubmitClaim,
}) => {
  const theme = useTheme();
  const [missingProviderBillingFields, setMissingProviderBillingFields] =
    useState<string[]>([]);
  const [missingPatientBillingFields, setMissingPatientBillingFields] =
    useState<string[]>([]);
  const [
    missingProviderEligibilityFields,
    setMissingProviderEligibilityFields,
  ] = useState<string[]>([]);
  const [missingPatientEligibilityFields, setMissingPatientEligibilityFields] =
    useState<string[]>([]);

  const receiptCardRef = useRef<HTMLDivElement | null>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const [showResubmitConfirmation, setShowResubmitConfirmation] =
    useState(false);

  useEffect(() => {
    if (patientData) {
      const eligibilityRequiredFields = ["first_name", "last_name", "payer_id"];
      const billingRequiredFields = [
        "first_name",
        "last_name",
        "dob",
        "address",
        "city",
        "state",
        "zip",
        "gender",
        "payer_id",
        "payer_name",
        "payer_relationship",
        "insurance_policy_number",
      ];
      const missingEligibilityFields = eligibilityRequiredFields.filter(
        (field) => !patientData[field as keyof Patient]
      );
      const missingBillingFields = billingRequiredFields.filter(
        (field) => !patientData[field as keyof Patient]
      );
      setMissingPatientEligibilityFields(missingEligibilityFields);
      setMissingPatientBillingFields(missingBillingFields);
    }
  }, [patientData]);

  useEffect(() => {
    const eligibilityRequiredProviderFields = ["npi_number", "tax_id"];
    const billingRequiredProviderFields = [
      "npi_number",
      "first_name",
      "last_name",
      "facility_address",
      "state",
      "city",
      "zip",
      "phone",
      "tax_id",
      "tax_id_type",
      "accept_insurance_payment_as_full",
    ];
    const missingEligibilityFields = eligibilityRequiredProviderFields.filter(
      (field) =>
        !userState?.providerBillingInfo?.[
          field as keyof typeof userState.providerBillingInfo
        ]
    );
    const missingBillingFields = billingRequiredProviderFields.filter(
      (field) =>
        !userState?.providerBillingInfo?.[
          field as keyof typeof userState.providerBillingInfo
        ]
    );
    setMissingProviderEligibilityFields(missingEligibilityFields);
    setMissingProviderBillingFields(missingBillingFields);
  }, [userState?.providerBillingInfo]);

  useEffect(() => {
    const checkOverflow = () => {
      if (receiptCardRef.current) {
        setIsOverflowing(
          receiptCardRef.current.scrollHeight >
            receiptCardRef.current.clientHeight
        );
      }
    };

    checkOverflow();
  }, [billingCodeExtra]);

  useEffect(() => {
    const isProviderDataComplete: boolean =
      missingProviderBillingFields.length === 0 &&
      missingProviderEligibilityFields.length === 0;
    const isPatientDataComplete: boolean =
      !!patientData &&
      missingPatientBillingFields.length === 0 &&
      missingPatientEligibilityFields.length === 0 &&
      patientData.eligibility === "yes";

    setReadyToSubmit(isProviderDataComplete && isPatientDataComplete);
  }, [
    missingProviderBillingFields,
    missingProviderEligibilityFields,
    missingPatientBillingFields,
    missingPatientEligibilityFields,
    patientData,
  ]);

  const fieldNameMapping = {
    // Patient fields
    first_name: "First Name",
    last_name: "Last Name",
    identifier: "Patient ID",
    dob: "Date of Birth",
    address: "Address",
    phone: "Phone Number",
    gender: "Gender",

    // Provider fields
    npi_number: "NPI Number",
    facility_address1: "Facility Address",
    facility_address2: "Facility Address 2",
    state: "State",
  };

  const getUserFriendlyFieldName = (fieldName: string): string => {
    return (
      fieldNameMapping[fieldName as keyof typeof fieldNameMapping] || fieldName
    );
  };

  const getMissingInfoMessage = () => {
    if (missingProviderBillingFields.length > 0) {
      return `Missing provider information: ${missingProviderBillingFields
        .map(getUserFriendlyFieldName)
        .join(", ")}`;
    }
    if (!patientData) {
      return "No patient assigned.";
    }
    if (missingPatientBillingFields.length > 0) {
      return `Missing patient information: ${missingPatientBillingFields
        .map(getUserFriendlyFieldName)
        .join(", ")}`;
    }
    return "";
  };

  const handleCTAClick = () => {
    if (missingProviderBillingFields.length > 0) {
      handleProviderInfoClick();
    } else if (!patientData) {
      handleAssignPatientClick();
    } else if (missingPatientBillingFields.length > 0) {
      handlePatientInfoClick();
    }
  };

  const getCTAText = (): string => {
    if (missingProviderBillingFields.length > 0) {
      return "Complete Provider Information";
    }
    if (!patientData) {
      return "Assign Patient";
    }
    if (missingPatientBillingFields.length > 0) {
      return "Complete Patient Information";
    }
    return "Complete Required Information";
  };

  const handleResubmitConfirm = () => {
    handleResubmitClaim();
    setShowResubmitConfirmation(false);
  };

  if (
    billingCodeExtra.status === "completed" &&
    !billingCodeExtra?.data?.billing_codes
  ) {
    return <></>;
  }

  return (
    <ContentCard
      sx={{
        minHeight: "275px",
      }}
      icon={
        billingCodeExtra.status === "processing" ? (
          <CircularProgress size={20} sx={{ mr: 2 }} />
        ) : (
          <Paid fontSize="medium" sx={{ mr: 2 }} />
        )
      }
      title="Bill with JotPsych"
      content={
        billingCodeExtra.status === "completed" ? (
          <Box
            sx={{
              flexGrow: 1,
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <ReceiptCard ref={receiptCardRef} sx={{ mb: 2 }}>
              {billingCodeExtra.data.billing_codes.billing_codes.map(
                (billing) => (
                  <Box key={billing.code} sx={{ mb: 0.5 }}>
                    <Tooltip
                      title={billing.evidence}
                      arrow
                      placement="left"
                      sx={{ typography: "caption", fontSize: "0.8rem" }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: "bold",
                          fontSize: "0.8rem",
                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}
                      >
                        {billing.code}: {billing.description}
                      </Typography>
                    </Tooltip>
                    {billingCodeExtra.data.diagnosis_codes.billing_code_mapping
                      .filter((mapping) => mapping.cpt_code === billing.code)
                      .flatMap((mapping) =>
                        mapping.supported_diagnosis_codes.map((diagnosis) => (
                          <Box key={diagnosis.code} sx={{ pl: 1 }}>
                            <Tooltip
                              title={diagnosis.evidence}
                              arrow
                              placement="left"
                              sx={{ typography: "caption", fontSize: "0.8rem" }}
                            >
                              <Typography
                                variant="caption"
                                sx={{
                                  fontSize: "0.7rem",
                                  "&:hover": {
                                    textDecoration: "underline",
                                  },
                                }}
                              >
                                {diagnosis.code}: {diagnosis.description}
                              </Typography>
                            </Tooltip>
                          </Box>
                        ))
                      )}
                  </Box>
                )
              )}
              {isOverflowing && (
                <>
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      height: "50px",
                      background: `linear-gradient(to bottom, rgba(255, 255, 255, .8), ${theme.palette.background.paper})`,
                      zIndex: 1, // Ensure the gradient is below the button
                    }}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "70px",
                      zIndex: 2, // Ensure the button is above the gradient
                    }}
                  ></Box>
                </>
              )}
            </ReceiptCard>
            {!readyToSubmit && (
              <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                <RoundedButton
                  variant="contained"
                  onClick={handleCTAClick}
                  sx={{ fontSize: "0.85rem" }}
                >
                  {getCTAText()}
                </RoundedButton>
              </Box>
            )}

            {!readyToSubmit ? (
              <Alert severity="warning">
                {getCTAText()} to submit billing.
              </Alert>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <RoundedButton
                  variant="contained"
                  sx={{
                    fontSize: "0.85rem",
                    fontWeight: "700",
                    whiteSpace: "nowrap",
                    px: 2,
                    opacity: billingSubmitted ? 0.5 : 1,
                    mb: 1,
                  }}
                  onClick={handleOpenBillingSidePane}
                  disabled={billingSubmitted || !readyToSubmit}
                >
                  {billingSubmitted ? (
                    <>
                      <CheckIcon sx={{ fontSize: "1rem", mr: 1 }} />
                      Billing Submitted
                    </>
                  ) : (
                    <>Review / Submit</>
                  )}
                </RoundedButton>
                {billingSubmitted && (
                  <MuiTooltip
                    title="Resubmitting will delete your previous claim"
                    arrow
                    placement="top"
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        cursor: "pointer",
                        color: theme.palette.primary.main,
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                      onClick={() => setShowResubmitConfirmation(true)}
                    >
                      Resubmit Claim
                    </Typography>
                  </MuiTooltip>
                )}
              </Box>
            )}
            <Modal
              open={showResubmitConfirmation}
              onClose={() => setShowResubmitConfirmation(false)}
              aria-labelledby="resubmit-confirmation-modal"
              aria-describedby="resubmit-confirmation-description"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 400,
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                  borderRadius: 2,
                }}
              >
                <Typography
                  id="resubmit-confirmation-modal"
                  variant="h6"
                  component="h2"
                  gutterBottom
                >
                  Confirm Resubmission
                </Typography>
                <Typography
                  id="resubmit-confirmation-description"
                  sx={{ mt: 2 }}
                >
                  Are you sure you want to resubmit? This will delete your
                  previous claim.
                </Typography>
                <Box
                  sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    onClick={() => setShowResubmitConfirmation(false)}
                    sx={{ mr: 2 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleResubmitConfirm}
                    variant="contained"
                    color="primary"
                  >
                    Confirm
                  </Button>
                </Box>
              </Box>
            </Modal>
          </Box>
        ) : null
      }
    />
  );
};

export default BillingSidebar;
