import React from "react";

export const textToParagraph = ({
  text,
  className,
}: {
  text: string;
  className?: string;
}) => {
  //<condition>anxiety</condition>
  //<med>prozac</med>
  const tags = ["condition", "med", "treatment", "test"];
  const tagRegex = new RegExp(
    `<(?:${tags.join("|")})([^>]*)>(.*?)<\/(?:${tags.join("|")})>`,
    "gi"
  );
  const matches = text.match(tagRegex);
  const children = [] as any;

  // If there are no matching tags, just set the text content of the <p> element
  if (!matches) {
    return <p className={className}>{text}</p>;
  }

  let lastIndex = 0;

  // Iterate over the matching tags and their contents
  for (const match of matches) {
    // Get the index of the current match in the original string
    const matchIndex = text.indexOf(match, lastIndex);

    // Get the text before the current match and add it to the <p> element
    const beforeText = text.substring(lastIndex, matchIndex);
    if (beforeText) {
      children.push(beforeText);
    }

    // Create a new <span> element and add it to the <p> element
    const spanContent = match
      .replace(new RegExp(`^<(${tags.join("|")})([^>]*)>`, "i"), "")
      .replace(new RegExp(`<\/(${tags.join("|")})>$`, "i"), "");
    const tagMatch = match.match(new RegExp(`^<(${tags.join("|")})`, "i"));
    const span = (
      <span className={tagMatch ? tagMatch[1] : ""}>{spanContent}</span>
    );

    children.push(span);

    // Update the lastIndex variable to the end of the current match
    lastIndex = matchIndex + match.length;
  }

  // Get the text after the last matching tag and add it to the <p> element
  const afterText = text.substring(lastIndex);
  if (afterText) {
    children.push(afterText);
  }

  return <p className={className}>{children.map((el: any) => el)}</p>;
};

export const stripTags = (str: string) => {
  const tags = ["test", "treatment", "med", "condition"];
  const regex = new RegExp(`<\\/?(?:${tags.join("|")})>`, "gi");
  return str.replace(regex, "");
};

type FormatType = "date" | "time" | "both" | "datelong";

export const formatDate = (
  epochTime: number,
  formatType: FormatType = "both"
): string => {
  const date = new Date(epochTime * 1000);

  let options: Intl.DateTimeFormatOptions = {};

  switch (formatType) {
    case "date":
      options = {
        year: "2-digit",
        month: "numeric",
        day: "numeric",
      };
      break;
    case "time":
      options = {
        hour: "numeric",
        minute: "numeric",
      };
      break;
    case "datelong":
      options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      break;
    case "both":
    default:
      options = {
        year: "2-digit",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      break;
  }

  const formattedDate = date.toLocaleString("en-US", options);
  return formattedDate;
};

export const truncateText = (element: HTMLElement, maxLines: number) => {
  const lineHeight = parseFloat(getComputedStyle(element).lineHeight);
  const maxHeight = maxLines * lineHeight;
  let text = element.textContent || "";

  while (element.scrollHeight > maxHeight && text.length > 0) {
    text = text.slice(0, -1);
    element.textContent = text + "...";
  }
};

export const formatDuration = (seconds: number): string => {
  const pad = (num: number): string => num.toString().padStart(2, "0");

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  if (hours > 0) {
    // Display hours and minutes
    return `${pad(hours)}:${pad(minutes)} hours`;
  } else if (minutes > 0) {
    // Display only minutes if duration is between 1 minute and 1 hour
    return `${pad(minutes)} minutes`;
  } else {
    // Display only seconds if duration is less than 1 minute
    return `${pad(remainingSeconds)} seconds`;
  }
};

export const unixTimestampToDaysRemaining = (epochTime: number): number => {
  const currentTimestamp = Math.floor(Date.now() / 1000); // Current timestamp in seconds
  const secondsRemaining = epochTime - currentTimestamp;

  if (secondsRemaining <= 0) {
    return 0; // The timestamp has already passed
  }

  const secondsInADay = 60 * 60 * 24;
  const daysRemaining = Math.ceil(secondsRemaining / secondsInADay);

  return daysRemaining;
};

// Step 1: Build a mixed array of strings and JSX elements with formatting applied.
const buildFormattedContent = (
  diffs: [number, string][]
): (string | JSX.Element)[] => {
  return diffs.reduce<(string | JSX.Element)[]>(
    (acc, [operation, text], index) => {
      if (operation === 1) {
        // Addition with bold formatting
        acc.push(
          <span key={`add-${index}`} className="font-bold">
            {text}
          </span>
        );
      } else if (operation === 0) {
        // Unchanged text
        acc.push(text);
      }
      // Ignore deletion (-1)
      return acc;
    },
    []
  );
};

// Step 2: Process the content for newlines, inserting divs for spacing.
const processContentForNewlines = (
  content: (string | JSX.Element)[]
): JSX.Element[] => {
  let keyCounter = 0; // For unique keys
  const processedContent: JSX.Element[] = [];

  content.forEach((item, index) => {
    if (typeof item === "string") {
      // For strings, split by '\n' and insert divs for spacing
      item.split("\n").forEach((part, partIndex, array) => {
        if (part) {
          processedContent.push(
            <span key={`text-${keyCounter++}`}>{part}</span>
          );
        }
        // Add divs for newline, except after the last segment
        if (partIndex < array.length - 1) {
          processedContent.push(
            <div key={`space-${keyCounter++}`} className="py-2"></div>
          );
        }
      });
    } else {
      // JSX Elements (formatted as bold) are added directly
      processedContent.push(item);
    }
  });

  return processedContent;
};

// Integrating the steps in the final render function
export const renderDiffs = (diffs: [number, string][]): JSX.Element => {
  const formattedContent = buildFormattedContent(diffs);
  const processedContent = processContentForNewlines(formattedContent);

  return <>{processedContent}</>;
};

export const compareVersions = (v1: string, v2: string) => {
  const v1Parts = v1.split(".").map(Number);
  const v2Parts = v2.split(".").map(Number);

  for (let i = 0; i < Math.max(v1Parts.length, v2Parts.length); i++) {
    const part1 = v1Parts[i] || 0;
    const part2 = v2Parts[i] || 0;

    if (part1 !== part2) {
      return part1 > part2 ? 1 : -1;
    }
  }

  return 0;
};

export const formatDateString = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
};

// export const renderDiff = (diff: [number, string], index: number) => {
//   // Split the text by newlines to process each line individually
//   const lines = diff[1].split("\n").filter((line) => line);

//   return lines.map((line, i) => {
//     console.log(line);

//     // Conditionally format text based on diff type
//     switch (diff[0]) {
//       case 1: // New text, should be bold
//         return (
//           <React.Fragment key={`${index}-${i}`}>
//             {i > 0 && <div className="py-2" />}
//             {/* Insert newlines between lines, but not before the first line */}
//             <span className="font-bold">{line}</span>
//           </React.Fragment>
//         );
//       case 0: // Original text
//         return (
//           <React.Fragment key={`${index}-${i}`}>
//             {i > 0 && <div className="py-2" />}
//             {/* Insert newlines between lines, but not before the first line */}
//             {/* {textToParagraph({ text: line, className: "py-2" })} */}
//             {line}
//           </React.Fragment>
//         );
//       default:
//         return null;
//     }
//   });
// };
