import React, { useEffect, useState } from "react";
import DashboardNote from "../components/Dashboard/DashboardNote";
import { useNavigate } from "react-router-dom";
import {
  NoteCounts,
  UserNoteTypeII,
  GamificationType,
  UserMetricsType,
  ReferralInfoType,
} from "../types/types";
import { Box, Typography, Link, Skeleton, Button, Stack } from "@mui/material";
import LayoutWrapper from "../components/layout/UILayout";
import { styled, useTheme } from "@mui/material/styles";
import DashboardNums from "../components/Dashboard/DashboardNums";
import { useUIState } from "../context/uiState";
import LinearProgress from "@mui/material/LinearProgress";
import RefreshIcon from "@mui/icons-material/Refresh";
import { BoxColumn } from "../components/layout/BoxColumn";
import { useUser } from "../context/user/context";
import { EmptyNoteList } from "../components/EmptyNoteList";
import {
  ForReviewNotesLoader,
  NoteCountsLoader,
  NotesLoader,
} from "../loaders/DashboardLoader";
import {
  MicNone as MicNoneIcon,
  EditNoteOutlined as EditNoteOutlinedIcon,
  ShapeLineOutlined as ShapeLineOutlinedIcon,
  BorderColor,
  Web,
} from "@mui/icons-material";
import { useThemeContext } from "../context/theme/context";
import { TermsOfServiceModal } from "../components/Modals/ToSModal";
import { NewFeatureModal } from "../components/Modals/NewFeatureModal";
import { DashboardCard } from "./Shared/DashboardCard";
import { UserNote } from "../components/UserNote";
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "50%",
  borderRadius: 20,
}));
import ActionableButtonCard from "./Shared/ActionableButtonCard";
import { RoundedButton } from "../styles/CustomButtons";
import { getDashboardCache, setDashboardCache, isDashboardCacheValid } from "../utils/notesCache";

const Dashboard = () => {
  const theme = useTheme();
  const {
    showAlertBanner,
    state: uiState,
    setState: setUIState,
  } = useUIState();
  const { platform } = uiState;

  const [notesForReview, setNotesForReview] = useState<
    UserNoteTypeII[] | undefined | null
  >();
  const [noNotesGenerated, setNoNotesGenerated] = useState<boolean>(false);
  const [noteCounts, setNoteCounts] = useState<NoteCounts>();
  const [loading, setLoading] = useState(true);
  const [loadError, setLoadError] = useState(false);
  const [notesLoading, setNotesLoading] = useState(true);

  const [gamificationInfo, setGamificationInfo] = useState<
    GamificationType | undefined
  >();
  const [userMetrics, setUserMetrics] = useState<UserMetricsType | undefined>();
  const [referralInfo, setReferralInfo] = useState<
    ReferralInfoType | undefined
  >();

  const { updateOnboardingStep, userState, newFeature, setNewFeature } =
    useUser();
  const { brandLanguage } = useThemeContext();

  const [showToSModal, setShowToSModal] = useState<boolean>(false);
  const [showAddToHomeScreen, setShowAddToHomeScreen] =
    useState<boolean>(false);

  const [showNewFeatureModal, setShowNewFeatureModal] =
    useState<boolean>(false);

  const navigate = useNavigate();

  const fetchNotesAndCounts = async (showLoading = true) => {
    try {
      // Check cache first
      const cache = getDashboardCache();
      
      if (cache && isDashboardCacheValid(cache)) {
        // Use cached data without changing loading state
        setNotesForReview(cache.notes);
        setNoteCounts({
          for_review_notes_count: cache.totalCount,
        });
        setNotesLoading(false);
        
        // Fetch fresh data in background without showing loading states
        fetchFreshData(false);
      } else {
        // No valid cache, show loading state and fetch fresh
        if (showLoading) {
          setLoading(true);
          setNotesLoading(true);
        }
        await fetchFreshData(true);
      }
    } catch (error) {
      console.log("Error fetching notes and counts:", error);
      setLoadError(true);
    } finally {
      if (showLoading) {
        setLoading(false);
      }
    }
  };

  const fetchFreshData = async (updateLoadingState = true) => {
    try {
      const { notes, totalCount } = await NotesLoader("reviewing", 20, 0);
      
      // Update data smoothly without clearing existing data first
      setNotesForReview((prevNotes) => {
        // Only update if new data is different
        if (JSON.stringify(prevNotes) !== JSON.stringify(notes)) {
          return notes;
        }
        return prevNotes;
      });
      
      setNoteCounts((prevCounts) => {
        if (prevCounts?.for_review_notes_count !== totalCount) {
          return { for_review_notes_count: totalCount };
        }
        return prevCounts;
      });

      if (totalCount === 0) {
        setNoNotesGenerated(true);
      }

      // Update cache
      setDashboardCache({
        notes,
        totalCount,
        filters: {
          category: "reviewing",
          limit: 20,
          offset: 0
        },
        timestamp: Date.now()
      });
    } finally {
      if (updateLoadingState) {
        setNotesLoading(false);
        setLoading(false);
      }
    }
  };

  const handleCopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      setUIState({ ...uiState, navbar: { showCopyNotification: true } });
    });
  };

  const findCorrespondingUserMetric = (metric: string) => {
    if (gamificationInfo && userMetrics) {
      const user_metric = userMetrics[metric];
      if (user_metric) {
        return user_metric;
      }
    }
    return 0;
  };

  const constructGamificationText = (info: GamificationType) => {
    if (!info.game_completed) {
      return `Level ${info.current_level}: ${info.activity_verb} ${info.target} ${info.activity_unit}.`;
    }
  };

  const constructMetricText = (info: GamificationType) => {
    return `${
      findCorrespondingUserMetric(info.current_metric) -
      info.starting_metric_value
    } of ${info.target} ${info.activity_unit}`;
  };

  const getGamificationProgress = (info: GamificationType) => {
    if (info && userMetrics) {
      const progress =
        ((findCorrespondingUserMetric(info.current_metric) -
          info.starting_metric_value) /
          info.target) *
        100;

      if (progress >= 100) {
        return 100;
      } else if (progress <= 0) {
        return 0;
      } else {
        return progress;
      }
    }
    return 0;
  };

  // const handleCloseOnboardingModal = () => {
  //   updateOnboardingStep("dashboard_onboarding_completed", true);
  //   setShowOnboardingModal(false);
  // };

  useEffect(() => {
    if (noteCounts && notesForReview) {
      setNotesLoading(false);
    }
  }, [noteCounts, notesForReview]);

  useEffect(() => {
    if (userState) {
      try {
        fetchNotesAndCounts();
        if (userState.gamification) {
          setGamificationInfo(userState.gamification);
        }
        if (userState.userMetrics) {
          setUserMetrics(userState.userMetrics);
        }
        if (userState.referralInfo) {
          setReferralInfo(userState.referralInfo);
        }
        if (userState.onboardingSteps) {
          if (userState.onboardingSteps.accepted_tos_0524 !== true) {
            setShowToSModal(true);
          }
        }
      } catch (e) {
        console.log("error in retrieval", e);
      }
    }
  }, [userState]);

  useEffect(() => {
    if (newFeature) {
      setShowNewFeatureModal(true);
    }

    if (userState) {
      if (
        platform !== "desktop" &&
        !newFeature &&
        userState.onboardingSteps?.accepted_tos_0524 === true
      ) {
        setShowAddToHomeScreen(true);
      }
    }
  }, [userState]);

  const RefreshComponent = () => {
    return (
      <Box
        onClick={() => {
          window.location.reload();
        }}
        sx={{
          "&:hover": {
            cursor: "pointer",
            borderRadius: "0.25rem",
            backgroundColor: "backgroundColors.secondary",
          },
        }}
      >
        <RefreshIcon style={{ fontSize: 30 }} />
      </Box>
    );
  };

  const UtilHomescreenComponents: React.FC = () => {
    const iconWrapperStyle = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 48,
      height: 48,
    };

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
        gap={2}
      >
        <Box
          sx={{ flex: { xs: "1 1 100%", sm: "1 1 30%" }, minWidth: "250px" }}
        >
          <ActionableButtonCard
            title="Record new note"
            Icon={
              <Box sx={iconWrapperStyle}>
                <MicNoneIcon sx={{ fontSize: 35 }} />
              </Box>
            }
            navigation="/record"
          />
        </Box>
        <Box
          sx={{ flex: { xs: "1 1 100%", sm: "1 1 30%" }, minWidth: "250px" }}
        >
          <ActionableButtonCard
            title="Review existing notes"
            Icon={
              <Box sx={iconWrapperStyle}>
                <EditNoteOutlinedIcon sx={{ fontSize: 35 }} />
              </Box>
            }
            navigation="/notes"
          />
        </Box>
        <Box
          sx={{ flex: { xs: "1 1 100%", sm: "1 1 30%" }, minWidth: "250px" }}
        >
          <ActionableButtonCard
            title="Build note template"
            Icon={
              <Box sx={iconWrapperStyle}>
                <Web sx={{ fontSize: 30 }} />
              </Box>
            }
            navigation="/templates"
          />
        </Box>
      </Box>
    );
  };

  const GetStartedActionButtons = () => {
    const navigate = useNavigate();
    const buttonStyles = {
      color: "textColors.brand",
      bgcolor: "backgroundColors.secondary",
      px: 2,
      py: 1,
      border: 0.1,
      borderColor: "gray",
      fontWeight: 600,
      "&:hover": {
        bgcolor: "backgroundColors.secondary",
      },
    };

    return (
      <Box sx={{ pt: 2 }}>
        <RoundedButton sx={buttonStyles} onClick={() => navigate(`/record`)}>
          Record my own note
        </RoundedButton>
        <RoundedButton
          sx={buttonStyles}
          onClick={() => navigate(`/demo/psychiatric-intake-demo`)}
        >
          Explore demo note
        </RoundedButton>
      </Box>
    );
  };
  const ReferralAndNotesToReview = () => {
    const ReferralCard = () => {
      return (
        <Box
          sx={{
            p: 1,
          }}
        >
          <Typography
            variant="h5"
            fontWeight="700"
            textAlign="left"
            sx={{ color: "newDesignColors.eggplant800", pb: 2 }}
          >
            Referral Program
          </Typography>
          <Typography variant="body1">
            Send your referral code to colleagues and we’ll give them $15 off
            their first month, and give you $40 off your next month! &nbsp;
            <Link
              sx={{
                fontWeight: "bold",
                textDecoration: "underline",
                color: "newDesignColors.eggplant700",
              }}
              href={`https://www.jotpsych.com/referral-program`}
              target="_blank"
              underline="hover"
            >
              {"Click here to learn more."}
            </Link>
          </Typography>
          <Typography sx={{ mt: 2 }} variant="h6" align="center">
            Your Referral Code:
          </Typography>
          <Typography
            variant="h5"
            fontStyle="bold"
            fontWeight="700"
            color="textColors.brand"
            align="center"
            sx={{ py: 2, cursor: "pointer" }}
            onClick={() =>
              referralInfo && handleCopyToClipboard(referralInfo.referral_code)
            }
          >
            {referralInfo?.referral_code}
          </Typography>
          <Typography
            variant="h6"
            fontStyle="normal"
            fontWeight="500"
            color="textColors.lightHeader"
            align="center"
            sx={{ padding: "0.5rem", cursor: "pointer" }}
            onClick={() =>
              referralInfo && handleCopyToClipboard(referralInfo.referral_code)
            }
          >
            (Click code to copy)
          </Typography>
          <Typography sx={{ mt: 2 }} variant="h6" align="center">
            Your Referral Earnings:
          </Typography>
          <Typography
            variant="h5"
            fontStyle="bold"
            fontWeight="700"
            align="center"
            color="textColors.lightHeader"
          >
            {referralInfo?.referral_earnings}
          </Typography>
        </Box>
      );
    };
    return (
      <Box
        sx={{
          display: { md: "flex" },
          width: "100%",
          justifyContent: "space-between",
          height: "100%", // Add this line
          maxHeight: "450px",
        }}
        gap={2}
      >
        {referralInfo && (
          <Box
            sx={{
              flex: referralInfo ? 1 : 3,
              height: "100%", // Change this line
              mb: { xs: 2, md: 0 }, // Add responsive margin
            }}
          >
            <DashboardCard
              children={<ReferralCard />}
              customStyles={{
                bgcolor: "#ffeac6",
                padding: 2,
              }}
            />
          </Box>
        )}
        <Box sx={{ flex: referralInfo ? 2 : 3, height: "100%" }}>
          <Box
            id="review-box"
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              height: "100%", // Add this line

              border: 1,
              borderColor: "borderColors.primary",
              bgcolor: "backgroundColors.primary",
              borderRadius: "0.75rem",
            }}
          >
            <Box
              sx={{
                bgcolor: "backgroundColors.secondary",
                borderBottom: 1,
                borderColor: "borderColors.primary",
                borderTopLeftRadius: "0.75rem",
                borderTopRightRadius: "0.75rem",
                display: "flex",
                padding: "1rem 1.5rem",
                justifyContent: "space-between",
              }}
            >
              <Typography
                fontSize="1.0625rem"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="1.59375rem"
                color="textColors.lightHeader"
                sx={{ justifyContent: "flex-start" }}
              >
                Notes to Review
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "100%",
                    backgroundColor: "newDesignColors.eggplant200",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "2rem",
                    height: "2rem",
                  }}
                >
                  <Typography
                    fontSize="1.0625rem"
                    fontStyle="normal"
                    fontWeight="600"
                    lineHeight="1.59375rem"
                    color="newDesignColors.eggplant700"
                  >
                    {noteCounts ? (
                      noteCounts.for_review_notes_count
                    ) : (
                      <Skeleton
                        variant="circular"
                        width={"80%"}
                        height={"80%"}
                      />
                    )}
                  </Typography>
                </Box>
                &nbsp;
                <RefreshComponent />
              </Box>
            </Box>
            <Box
              id="note-content"
              sx={{
                display: "flex",
                flexDirection: "column",
                bgcolor: "backgroundColors.primary",
                borderRadius: "0.75rem",
                overflowY: "auto", // Change to auto
                flex: 1, // Add this line
              }}
            >
              {(!notesForReview || noNotesGenerated) && (
                <Box height="100%">
                  {notesForReview === undefined && (
                    <>
                      <Box py={2}>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                      </Box>
                      <Box py={2}>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                      </Box>
                      <Box py={2}>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                      </Box>
                    </>
                  )}
                  {(noNotesGenerated || notesForReview === null) && (
                    <Box sx={{ height: "100%" }}>
                      <UserNote
                        index={0}
                        key={0}
                        status={"completed"}
                        review_status={"ready_for_review"}
                        note_id={"xyz"}
                        note_title={"Demo Intake Note"}
                        time={Date.now() / 1000}
                        summary={`Want to see what a ${brandLanguage.brandName} note looks like? Click here to view an example note of a Psychiatric Intake note.`}
                        link={`/demo/psychiatric-intake-demo`}
                      />
                      <hr />
                      <Box>
                        <Box
                          sx={{
                            p: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                            justifyContent: "center",
                          }}
                        >
                          <Typography sx={{ mb: 2, color: "gray" }}>
                            You haven't recorded any notes yet. To get started,
                            we suggest you either explore our pre-made demo note
                            or dive in and record your own.
                          </Typography>
                          <GetStartedActionButtons />
                        </Box>
                      </Box>
                      {/* <EmptyNoteList /> */}
                    </Box>
                  )}
                </Box>
              )}
              {notesForReview &&
                notesForReview.map((note: UserNoteTypeII, index: number) => {
                  return (
                    <UserNote
                      key={note.note_id}
                      index={index}
                      note_id={note.note_id}
                      note_title={note.note_title}
                      patient_id={note.patient_id}
                      patient_name={note.patient_name}
                      status={note.status}
                      review_status={note.review_status}
                      time={note.time_uploaded}
                      summary={note.summary}
                      link={
                        note.status === "completed"
                          ? `/note/${note.note_id}`
                          : `/status/${note.note_id}`
                      }
                      origin="dashboard"
                      error_message={note.error_message}
                      completion_status={note.completion_status}
                      completion_timestamp={note.completion_timestamp}
                    />
                  );
                })}
              {notesForReview && (
                <Box
                  sx={{
                    display: "flex",
                    flex: "1 1 0%",
                    bgcolor: "backgroundColors.primary",
                    border: 0,
                    borderTop: 1,
                    borderBottomLeftRadius: "0.75rem",
                    borderBottomRightRadius: "0.75rem",
                    borderColor: "borderColors.primary",
                    height: "min-content",
                    maxHeight: "100%",
                    padding: "1.5rem",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "0.5rem",
                    "&:hover": {
                      cursor: "pointer",
                      bgcolor: "backgroundColors.secondary",
                    },
                  }}
                  onClick={() => {
                    navigate("/notes");
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography
                      fontSize="0.9375rem"
                      fontStyle="normal"
                      fontWeight="700"
                      lineHeight="1.40625rem"
                      color="textColors.lightHeader"
                    >
                      View all Notes
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const Challenge = () => {
    const ChallengeCard = () => {
      return (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              color: "newDesignColors.eggplant800",
            }}
          >
            <Typography variant="h6" fontStyle="normal">
              Your {brandLanguage.brandName} Challenge
            </Typography>

            {!userState?.gamification?.game_completed ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    py: 1,
                  }}
                >
                  <Typography variant="h6" fontStyle="normal" fontWeight="600">
                    {gamificationInfo &&
                      constructGamificationText(gamificationInfo)}
                  </Typography>
                </Box>
                <Typography
                  variant="subtitle1"
                  fontStyle="normal"
                  fontWeight="500"
                >
                  {gamificationInfo?.reward.text}.{" "}
                  <Link
                    href={`https://${brandLanguage.brandSite}/rewardsandreferrals`}
                    target="_blank"
                    underline="hover"
                    sx={{ fontWeight: "bold", textDecoration: "underline" }}
                  >
                    {"Click here"}
                  </Link>{" "}
                  to learn more about {brandLanguage.brandName} challenges
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    height: "30%",
                    pt: 2,
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "15px",
                      minHeight: "5px",
                    }}
                  >
                    <BorderLinearProgress
                      variant="determinate"
                      value={
                        gamificationInfo &&
                        getGamificationProgress(gamificationInfo)
                      }
                    />
                  </Box>
                  <Typography
                    variant="subtitle2"
                    fontStyle="normal"
                    fontWeight="500"
                  >
                    {gamificationInfo && constructMetricText(gamificationInfo)}
                  </Typography>
                </Box>
              </>
            ) : (
              <Box>
                <Typography variant="h6" fontWeight={700} sx={{ py: 1 }}>
                  Nice work! You've completed your challenge.{" "}
                  {String.fromCodePoint(0x1f389)}
                </Typography>
                <Typography variant="body1">
                  Keep an eye out for your reward and for future challenges!
                </Typography>
              </Box>
            )}
          </Box>
        </>
      );
    };
    return (
      <>
        <DashboardCard
          children={<ChallengeCard />}
          customStyles={{
            maxHeight: "200px",
            bgcolor: "newDesignColors.orange100",
            padding: 4,
          }}
        />
      </>
    );
  };
  return (
    <>
      <LayoutWrapper>
        <LayoutWrapper.MainContent>
          <Stack
            spacing={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "85%",
              height: "100%",
              margin: "auto",
              mt: 4,
              mb: 4,
              color: "newDesignColors.eggplant800",
            }}
          >
            {userState?.userInfo?.name &&
              (!userState?.onboardingSteps?.generated_first_note ? (
                <Typography variant="h5" fontWeight={700} sx={{ pb: 2 }}>
                  Welcome, {userState?.userInfo?.name}!
                </Typography>
              ) : (
                <Typography variant="h5" fontWeight={700} sx={{ pb: 2 }}>
                  Welcome back, {userState?.userInfo?.name}!
                </Typography>
              ))}
            {!userState?.onboardingSteps?.generated_first_note && (
              <EmptyNoteList />
            )}
            {userState?.onboardingSteps?.generated_first_note && (
              <>
                {gamificationInfo && <Challenge />}
                <UtilHomescreenComponents />
              </>
            )}
            <ReferralAndNotesToReview />
            <br />
          </Stack>
        </LayoutWrapper.MainContent>
      </LayoutWrapper>
      <TermsOfServiceModal
        isOpen={showToSModal}
        onClose={() => setShowToSModal(false)}
      />
      <NewFeatureModal
        isOpen={showNewFeatureModal}
        onClose={() => {
          setShowNewFeatureModal(false);
          setNewFeature(false);
        }}
      />
      {/* {showAddToHomeScreen && <AddToHomeScreen />} */}
    </>
  );
};

export default Dashboard;
