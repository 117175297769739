import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Tooltip,
  IconButton,
  TextField,
  CircularProgress,
  useTheme,
  Alert,
  Button,
  Modal,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  Paid,
  InfoOutlined,
  Edit as EditIcon,
  Check as CheckIcon,
  MoneyOutlined,
  ThumbUp,
  ThumbDown,
} from "@mui/icons-material";
import { ContentCard } from "../../Views/Shared/ContentCard";
import { ConditionalTooltip } from "../ConditionalTooltip";
import { RoundedButton } from "../../styles/CustomButtons";
import { GreenBubble, YellowBubble, RedBubble } from "../Icons/ColoredBubbles";
import {
  BillingCodeExtraData,
  NoteExtraType,
  Patient,
} from "../../types/types";
import { UserState, useUser } from "../../context/user/context";
import { styled } from "@mui/material/styles";
import { Tooltip as MuiTooltip } from "@mui/material";
import { trackEvent } from "../../utils/analytics_utils";
import SlideSidePane from "../../Views/Shared/SlideSidePane";
import BillingCodeSection from "./BillingCodeSection";
import { AnimatedCheckmark } from "../SVGIcon";
import APIService from "../../services/APIService";

interface TestGatherBillingSidebarProps {
  userState: UserState;
  noteId: string;
}

interface BetaInterestFields {
  codeBill: boolean;
  submitClaims: boolean;
  submitSuperbills: boolean;
  processPayments: boolean;
  appealClaims: boolean;
}

const TestGatherBillingSidebar: React.FC<TestGatherBillingSidebarProps> = ({
  userState,
  noteId,
}) => {
  const { getAccessToken } = useUser();
  const [isAccuracyReviewOpen, setIsAccuracyReviewOpen] = useState(false);
  const [betaInterest, setBetaInterest] = useState<BetaInterestFields>({
    codeBill: false,
    submitClaims: false,
    submitSuperbills: false,
    processPayments: false,
    appealClaims: false,
  });
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const hasTrackedRef = useRef(false);

  const handleMouseEnter = () => {
    if (!hasTrackedRef.current) {
      trackEvent({
        event: "test_gather_billing_sidebar_view",
      });
      hasTrackedRef.current = true;
    }
  };

  const handleBetaInterestSubmit = async () => {
    try {
      const accessToken = await getAccessToken();
      const betaInterestPayload = {
        userId: userState.user_id,
        phoneNumber: phoneNumber,
        interests: betaInterest,
      };

      const response = await APIService.makeAPIPostRequest({
        requestString: "/billing/submitBillingBetaInterest",
        accessToken,
        body: betaInterestPayload,
      });

      if (response.ok) {
        setIsAccuracyReviewOpen(false);
        trackEvent({
          event: "test_gather_billing_sidebar_beta_interest_submit",
        });
      } else {
        console.error("Failed to submit beta interest:", response.error);
      }
    } catch (error) {
      console.error("Error submitting beta interest:", error);
    }
  };

  return (
    <>
      <SlideSidePane
        open={isAccuracyReviewOpen}
        onClose={() => setIsAccuracyReviewOpen(false)}
        title="Bill with JotPsych"
      >
        <>
          <Typography sx={{ mb: 2 }}>
            As you know, we're determined to cut down on your admin time. You
            didn't get into behavioral health to write notes. And you definitely
            didn't get into medicine to nitpick with insurance companies.
          </Typography>

          <Typography sx={{ mb: 2 }}>
            Our vision is that you never worry about billing. You just submit
            your billing at the end of the visit, and get promptly paid.
          </Typography>

          <Typography sx={{ mb: 3 }}>
            To make this feature the best it can be, we're inviting Beta testers
            to shape it with us. Select the features you'd like JotPsych to
            develop, and you'll receive early access and a chance to provide
            feedback:
          </Typography>

          <Box sx={{ pl: 3, mb: 3 }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={betaInterest.codeBill}
                    onChange={(e) =>
                      setBetaInterest((prev) => ({
                        ...prev,
                        codeBill: e.target.checked,
                      }))
                    }
                  />
                }
                label="JotPsych codes bills (we'll learn from your edits to improve continuously)"
                sx={{ mb: 1 }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={betaInterest.submitClaims}
                    onChange={(e) =>
                      setBetaInterest((prev) => ({
                        ...prev,
                        submitClaims: e.target.checked,
                      }))
                    }
                  />
                }
                label="JotPsych submits claims directly to insurance for reimbursement"
                sx={{ mb: 1 }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={betaInterest.submitSuperbills}
                    onChange={(e) =>
                      setBetaInterest((prev) => ({
                        ...prev,
                        submitSuperbills: e.target.checked,
                      }))
                    }
                  />
                }
                label="JotPsych sends superbills to clients for payment"
                sx={{ mb: 1 }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={betaInterest.processPayments}
                    onChange={(e) =>
                      setBetaInterest((prev) => ({
                        ...prev,
                        processPayments: e.target.checked,
                      }))
                    }
                  />
                }
                label="JotPsych tracks and processes payments directly to your bank account"
                sx={{ mb: 1 }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={betaInterest.appealClaims}
                    onChange={(e) =>
                      setBetaInterest((prev) => ({
                        ...prev,
                        appealClaims: e.target.checked,
                      }))
                    }
                  />
                }
                label="JotPsych appeals denied claims on your behalf"
                sx={{ mb: 1 }}
              />
            </FormGroup>
          </Box>

          <Typography sx={{ mb: 2, fontWeight: 500 }}>
            Best phone number at which to reach you (via text):
          </Typography>
          <TextField
            fullWidth
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            sx={{ mb: 3 }}
            placeholder="(123) 456-7890"
          />

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <RoundedButton
              variant="contained"
              color="primary"
              onClick={handleBetaInterestSubmit}
            >
              Submit
            </RoundedButton>
          </Box>
        </>
      </SlideSidePane>

      <Box onMouseEnter={handleMouseEnter}>
        <ContentCard
          sx={{ minHeight: "auto" }}
          icon={<Paid fontSize="medium" sx={{ mr: 2 }} />}
          title="Bill with JotPsych"
          content={
            <Box sx={{ textAlign: "center" }}>
              <Typography sx={{ mb: 2 }}>
                Want JotPsych to help you submit your billing?
              </Typography>
              <RoundedButton
                variant="contained"
                color="primary"
                onClick={() => setIsAccuracyReviewOpen(true)}
              >
                Join Billing Beta
              </RoundedButton>
            </Box>
          }
        />
      </Box>
    </>
  );
};

export default TestGatherBillingSidebar;
