import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import Logo from "../Icons/Logo";
import { AccountCircle } from "@mui/icons-material";
import {
  AdminSection,
  AvailableModule,
} from "../../types/types";
import {  Draggable } from "react-beautiful-dnd";

import { useThemeContext } from "../../context/theme/context";
import {
  Close,
  DragIndicator,
  Star,
} from "@mui/icons-material";
import { HoverContent } from "../Shared/HoverContent";

interface SectionBlockProps {
  section: AdminSection;
  handleClick?: () => void;
  handleRevert?: () => void;
  availableModules?: AvailableModule[];
  templateModuleMap?: { [key: string]: string };
}

export const SectionBlock = ({
  section,
  handleClick,
  handleRevert,
  availableModules,
  templateModuleMap,
}: SectionBlockProps) => {
  const moduleInfo = availableModules?.find(
    (module) => module.module_id === section.module_id
  );
  return (
    <Card
      sx={{
        my: 1.5,
        borderColor: "borderColors.primary",
        border: "0.5px solid",
        borderRadius: "0.4cm",
        "&:hover": {
          boxShadow: 3, // Adjust shadow on hover
        },
      }}
      onClick={handleClick}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            id="module-text"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              flexGrow: 1,
            }}
          >
            <Box>
              <Typography
                fontStyle="normal"
                fontWeight="700"
                sx={{
                  cursor: "pointer",
                  display: "inline-block",
                  "&:hover + .hover-content": {
                    cursor: "pointer",
                    display: "block",
                    opacity: 1,
                    visibility: "visible",
                    transform: "translateY(0%)",
                  },
                }}
              >
                {section.display_name}
              </Typography>
              {/* Hover content */}
              {moduleInfo && (
                <HoverContent
                  title={section.display_name}
                  content={
                    moduleInfo?.example || moduleInfo?.public_example || ""
                  }
                />
              )}
            </Box>

            <Box>
              <Typography fontSize=".8rem" fontWeight="300">
                {moduleInfo?.description || moduleInfo?.public_description}
              </Typography>
            </Box>
          </Box>
          {templateModuleMap && templateModuleMap[section.module_id] && (
            <Tooltip
              title={"This section has been customized."}
              placement="top"
              arrow
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  "&:hover .revert-button": {
                    display: "inherit",
                  },
                }}
              >
                <Star />
                <Typography fontSize={12}>Customized</Typography>
                {handleRevert && (
                  <Button
                    variant="text"
                    className="revert-button"
                    sx={{ display: "none" }}
                    onClick={handleRevert}
                  >
                    <Typography fontSize={10}>Revert?</Typography>
                  </Button>
                )}
              </Box>
            </Tooltip>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

interface DraggableSectionBlockProps {
  section: AdminSection;
  index: number;
  handleClick?: () => void;
  handleRemove?: () => void;
  handleRevert?: () => void;
  availableModules?: AvailableModule[];
  templateModuleMap?: { [key: string]: string };
}

export const DraggableSectionBlock = ({
  section,
  index,
  handleClick,
  handleRemove,
  handleRevert,
  availableModules,
  templateModuleMap,
}: DraggableSectionBlockProps) => {
  const moduleInfo = availableModules?.find(
    (module) => module.module_id === section.module_id
  );
  const { brandLanguage } = useThemeContext();
  return (
    <Draggable
      key={section.module_id}
      draggableId={section.module_id}
      index={index}
    >
      {(provided) => (
        <Card
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{
            my: 1.5,
            borderColor: "borderColors.primary",
            border: "0.5px solid",
            borderRadius: "0.4cm",
            "&:hover": {
              boxShadow: 3, // Adjust shadow on hover
            },
          }}
          onClick={handleClick}
        >
          <CardContent>
            <Box sx={{ mb: 0 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: 0,
                  marginRight: "0.5rem",
                }}
              >
                {moduleInfo?.is_custom_user_module ? (
                  <Tooltip
                    title="This is your customized section."
                    placement={"top"}
                    arrow
                  >
                    <Box>
                      <AccountCircle />
                    </Box>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={`This is a ${brandLanguage.brandName} section.`}
                    placement={"top"}
                    arrow
                  >
                    <Box>
                      <Logo size={22} />
                    </Box>
                  </Tooltip>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box
                id="drag-handle"
                {...provided.dragHandleProps}
                sx={{ marginLeft: -1, marginRight: 2 }}
              >
                <DragIndicator />
              </Box>
              <Box
                id="module-text"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  flexGrow: 1,
                }}
              >
                <Box>
                  <Typography
                    fontStyle="normal"
                    fontWeight="700"
                    sx={{
                      cursor: "pointer",
                      display: "inline-block",
                      "&:hover + .hover-content": {
                        opacity: 1,
                        visibility: "visible",
                      },
                    }}
                  >
                    {section.display_name}
                  </Typography>
                  {/* Hover content */}
                  {moduleInfo && (
                    <HoverContent
                      title={section.display_name}
                      content={
                        moduleInfo?.example || moduleInfo?.public_example || ""
                      }
                    />
                  )}
                </Box>
                <Box>
                  <Typography fontSize=".8rem" fontWeight="300">
                    {moduleInfo?.description || moduleInfo?.public_description}
                  </Typography>
                </Box>
                {/* <Box sx={{ pt: 1}}>
                  <Chip
                    icon={moduleInfo?.is_custom_user_module ? <AutoAwesome sx={{ fontSize: 12 }}/> : undefined}
                    label={moduleInfo?.is_custom_user_module ? "Custom" : "JotPsych"}
                    color={moduleInfo?.is_custom_user_module ? "success" : "primary"}
                    sx={{ borderRadius: 3, py: 0, px: 1 }}
                  />
                </Box> */}
              </Box>
              <Box
                id="interactions"
                sx={{ display: "flex", justifyContent: "flex-start" }}
              >
                {templateModuleMap && templateModuleMap[section.module_id] && (
                  <Tooltip
                    title={"This section has been customized."}
                    placement="top"
                    arrow
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        "&:hover .revert-button": {
                          display: "inherit",
                        },
                      }}
                    >
                      <Star />
                      <Typography fontSize={12}>Customized</Typography>
                      {handleRevert && (
                        <Button
                          variant="text"
                          className="revert-button"
                          sx={{ display: "none" }}
                          onClick={handleRevert}
                        >
                          <Typography fontSize={10}>Revert?</Typography>
                        </Button>
                      )}
                    </Box>
                  </Tooltip>
                )}
                <IconButton
                  onClick={handleRemove}
                  sx={{ marginLeft: 2, height: 40 }}
                >
                  <Close />
                </IconButton>
              </Box>
            </Box>
          </CardContent>
        </Card>
      )}
    </Draggable>
  );
};

