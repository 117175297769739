import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Tooltip,
  IconButton,
  TextField,
  CircularProgress,
  useTheme,
  Alert,
  Button,
  Modal,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  Paid,
  InfoOutlined,
  Edit as EditIcon,
  Check as CheckIcon,
  MoneyOutlined,
  ThumbUp,
  ThumbDown,
} from "@mui/icons-material";
import { ContentCard } from "../../Views/Shared/ContentCard";
import { ConditionalTooltip } from "../ConditionalTooltip";
import { RoundedButton } from "../../styles/CustomButtons";
import { GreenBubble, YellowBubble, RedBubble } from "../Icons/ColoredBubbles";
import {
  BillingCodeExtraData,
  NoteExtraType,
  Patient,
} from "../../types/types";
import { UserState, useUser } from "../../context/user/context";
import { styled } from "@mui/material/styles";
import { Tooltip as MuiTooltip } from "@mui/material";
import { trackEvent } from "../../utils/analytics_utils";
import SlideSidePane from "../../Views/Shared/SlideSidePane";
import BillingCodeSection from "./BillingCodeSection";
import { AnimatedCheckmark } from "../SVGIcon";
import APIService from "../../services/APIService";

// Define a styled component for the receipt card
const ReceiptCard = styled(Box)<{ maxHeight?: string }>(
  ({ theme, maxHeight }) => ({
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1), // Reduced padding
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    fontSize: "1rem", // Smaller font size
    lineHeight: 1.1, // More condensed line height
    maxHeight: maxHeight || "30vh", // Set max height with default value
    overflow: "hidden", // Hide overflow
    position: "relative", // Position relative for overlay
  })
);

const BetaBadge = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 20,
  left: -30,
  backgroundColor: theme.palette.primary.main,
  color: "white",
  padding: "4px 30px",
  fontSize: "0.75rem",
  fontWeight: "bold",
  transform: "rotate(-45deg)",
  transformOrigin: "center",
  boxShadow: "0 3px 10px -5px rgba(0, 0, 0, 0.3)",
  "&:before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    borderBottom: `10px solid ${theme.palette.primary.dark}`,
    borderLeft: "10px solid transparent",
    width: 0,
    height: 0,
  },
  "&:after": {
    content: '""',
    position: "absolute",
    top: 0,
    right: 0,
    borderBottom: `10px solid ${theme.palette.primary.dark}`,
    borderRight: "10px solid transparent",
    width: 0,
    height: 0,
  },
  zIndex: 1,
}));

interface TestGatherBillingSidebarProps {
  billingCodeExtra: { status: string; data: BillingCodeExtraData };
  patientData: Patient | null | undefined;
  userState: UserState;
  showTooltips: boolean;
  billingSubmitted: boolean;
  handleProviderInfoClick: () => void;
  handlePatientInfoClick: () => void;
  handlePatientEligibilityClick: () => void;
  handleOpenBillingSidePane: () => void;
  handleAssignPatientClick: () => void;
  handleResubmitClaim: () => void;
  noteId: string;
}

interface BetaInterestFields {
  codeBill: boolean;
  submitClaims: boolean;
  submitSuperbills: boolean;
  processPayments: boolean;
  appealClaims: boolean;
}

const TestGatherBillingSidebar: React.FC<TestGatherBillingSidebarProps> = ({
  billingCodeExtra,
  patientData,
  userState,
  showTooltips,
  billingSubmitted,
  handleProviderInfoClick,
  handlePatientInfoClick,
  handlePatientEligibilityClick,
  handleOpenBillingSidePane,
  handleAssignPatientClick,
  handleResubmitClaim,
  noteId,
}) => {
  const theme = useTheme();
  const { getAccessToken } = useUser();
  const [missingProviderBillingFields, setMissingProviderBillingFields] =
    useState<string[]>([]);
  const [missingPatientBillingFields, setMissingPatientBillingFields] =
    useState<string[]>([]);
  const [
    missingProviderEligibilityFields,
    setMissingProviderEligibilityFields,
  ] = useState<string[]>([]);
  const [missingPatientEligibilityFields, setMissingPatientEligibilityFields] =
    useState<string[]>([]);

  const receiptCardRef = useRef<HTMLDivElement | null>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const [showResubmitConfirmation, setShowResubmitConfirmation] =
    useState(false);

  const [accuracyFeedback, setAccuracyFeedback] = useState<true | false | null>(
    null
  );
  const [isAccuracyReviewOpen, setIsAccuracyReviewOpen] = useState(false);

  const hasTrackedRef = useRef(false);

  const [billingFields, setBillingFields] = useState(() => {
    const savedFields = localStorage.getItem(`billingFields-${noteId}`);
    return savedFields
      ? JSON.parse(savedFields)
      : billingCodeExtra.data.billing_codes.billing_codes.map((billing) => ({
          code: billing.code,
          chargeAmount: "",
          units: "1",
          modifier: "",
          diagnosisCodes:
            billingCodeExtra.data.diagnosis_codes.billing_code_mapping
              .find((mapping) => mapping.cpt_code === billing.code)
              ?.supported_diagnosis_codes.map((diagnosis) => diagnosis.code) ||
            [],
        }));
  });

  const [comment, setComment] = useState<string>("");
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

  const [betaInterest, setBetaInterest] = useState<BetaInterestFields>({
    codeBill: false,
    submitClaims: false,
    submitSuperbills: false,
    processPayments: false,
    appealClaims: false,
  });

  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const handleBetaInterestSubmit = async () => {
    try {
      const accessToken = await getAccessToken();
      const betaInterestPayload = {
        userId: userState.user_id,
        phoneNumber: phoneNumber,
        interests: betaInterest,
      };

      const response = await APIService.makeAPIPostRequest({
        requestString: "/billing/submitBillingBetaInterest",
        accessToken,
        body: betaInterestPayload,
      });

      if (response.ok) {
        setIsAccuracyReviewOpen(false);
      } else {
        console.error("Failed to submit beta interest:", response.error);
      }
    } catch (error) {
      console.error("Error submitting beta interest:", error);
    }
  };

  const handleMouseEnter = () => {
    if (!hasTrackedRef.current) {
      trackEvent({
        event: "test_gather_billing_sidebar_view",
      });
      hasTrackedRef.current = true;
    }
  };

  const handleSubmitAccuracyFeedback = (isAccurate: boolean) => {
    trackEvent({
      event: "test_gather_billing_sidebar_accuracy_feedback",
      is_accurate: isAccurate,
    });
    setAccuracyFeedback(isAccurate);
    setIsAccuracyReviewOpen(true);
  };

  const handleSubmitFinalFeedback = async () => {
    try {
      const accessToken = await getAccessToken();
      const feedbackPayload = {
        noteId,
        userId: userState.user_id,
        rawBillingData: billingCodeExtra.data,
        accuracyFeedback,
        updatedBillingFields: billingFields,
        comment,
      };

      const response = await APIService.makeAPIPostRequest({
        requestString: "/billing/submitBillingSidebarFeedback",
        accessToken,
        body: feedbackPayload,
      });

      if (response.ok) {
        setFeedbackSubmitted(true);
      } else {
        console.error("Failed to submit feedback:", response.error);
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  useEffect(() => {
    if (patientData) {
      const eligibilityRequiredFields = ["first_name", "last_name", "payer_id"];
      const billingRequiredFields = [
        "first_name",
        "last_name",
        "dob",
        "address",
        "city",
        "state",
        "zip",
        "gender",
        "payer_id",
        "payer_name",
        "payer_relationship",
        "insurance_policy_number",
      ];
      const missingEligibilityFields = eligibilityRequiredFields.filter(
        (field) => !patientData[field as keyof Patient]
      );
      const missingBillingFields = billingRequiredFields.filter(
        (field) => !patientData[field as keyof Patient]
      );
      setMissingPatientEligibilityFields(missingEligibilityFields);
      setMissingPatientBillingFields(missingBillingFields);
    }
  }, [patientData]);

  useEffect(() => {
    const eligibilityRequiredProviderFields = ["npi_number", "tax_id"];
    const billingRequiredProviderFields = [
      "npi_number",
      "first_name",
      "last_name",
      "facility_address",
      "state",
      "city",
      "zip",
      "phone",
      "tax_id",
      "tax_id_type",
      "accept_insurance_payment_as_full",
    ];
    const missingEligibilityFields = eligibilityRequiredProviderFields.filter(
      (field) =>
        !userState?.providerBillingInfo?.[
          field as keyof typeof userState.providerBillingInfo
        ]
    );
    const missingBillingFields = billingRequiredProviderFields.filter(
      (field) =>
        !userState?.providerBillingInfo?.[
          field as keyof typeof userState.providerBillingInfo
        ]
    );
    setMissingProviderEligibilityFields(missingEligibilityFields);
    setMissingProviderBillingFields(missingBillingFields);
  }, [userState?.providerBillingInfo]);

  useEffect(() => {
    const checkOverflow = () => {
      if (receiptCardRef.current) {
        setIsOverflowing(
          receiptCardRef.current.scrollHeight >
            receiptCardRef.current.clientHeight
        );
      }
    };

    checkOverflow();
  }, [billingCodeExtra]);

  useEffect(() => {
    const isProviderDataComplete: boolean =
      missingProviderBillingFields.length === 0 &&
      missingProviderEligibilityFields.length === 0;
    const isPatientDataComplete: boolean =
      !!patientData &&
      missingPatientBillingFields.length === 0 &&
      missingPatientEligibilityFields.length === 0 &&
      patientData.eligibility === "yes";

    setReadyToSubmit(isProviderDataComplete && isPatientDataComplete);
  }, [
    missingProviderBillingFields,
    missingProviderEligibilityFields,
    missingPatientBillingFields,
    missingPatientEligibilityFields,
    patientData,
  ]);

  const fieldNameMapping = {
    // Patient fields
    first_name: "First Name",
    last_name: "Last Name",
    identifier: "Patient ID",
    dob: "Date of Birth",
    address: "Address",
    phone: "Phone Number",
    gender: "Gender",

    // Provider fields
    npi_number: "NPI Number",
    facility_address1: "Facility Address",
    facility_address2: "Facility Address 2",
    state: "State",
  };

  const getUserFriendlyFieldName = (fieldName: string): string => {
    return (
      fieldNameMapping[fieldName as keyof typeof fieldNameMapping] || fieldName
    );
  };

  const getMissingInfoMessage = () => {
    if (missingProviderBillingFields.length > 0) {
      return `Missing provider information: ${missingProviderBillingFields
        .map(getUserFriendlyFieldName)
        .join(", ")}`;
    }
    if (!patientData) {
      return "No patient assigned.";
    }
    if (missingPatientBillingFields.length > 0) {
      return `Missing patient information: ${missingPatientBillingFields
        .map(getUserFriendlyFieldName)
        .join(", ")}`;
    }
    return "";
  };

  const handleCTAClick = () => {
    if (missingProviderBillingFields.length > 0) {
      handleProviderInfoClick();
    } else if (!patientData) {
      handleAssignPatientClick();
    } else if (missingPatientBillingFields.length > 0) {
      handlePatientInfoClick();
    }
  };

  const getCTAText = (): string => {
    if (missingProviderBillingFields.length > 0) {
      return "Complete Provider Information";
    }
    if (!patientData) {
      return "Assign Patient";
    }
    if (missingPatientBillingFields.length > 0) {
      return "Complete Patient Information";
    }
    return "Complete Required Information";
  };

  const handleResubmitConfirm = () => {
    handleResubmitClaim();
    setShowResubmitConfirmation(false);
  };

  const handleBillingFieldChange = (
    index: number,
    field: keyof (typeof billingFields)[0],
    value: string
  ) => {
    const newBillingFields = [...billingFields];
    //@ts-ignore
    newBillingFields[index][field] = value;
    setBillingFields(newBillingFields);
  };

  const handleAddDiagnosisCode = (index: number, code: string) => {
    const newBillingFields = [...billingFields];
    if (code && !newBillingFields[index].diagnosisCodes.includes(code)) {
      newBillingFields[index].diagnosisCodes.push(code);
      setBillingFields(newBillingFields);
    }
  };

  const handleRemoveDiagnosisCode = (index: number, code: string) => {
    const newBillingFields = [...billingFields];
    newBillingFields[index].diagnosisCodes = newBillingFields[
      index
    ].diagnosisCodes.filter((c: string) => c !== code);
    setBillingFields(newBillingFields);
  };

  const handleRemoveBillingCodeSection = (index: number) => {
    console.debug("Removing billing code section at index:", index);
    const newBillingFields = billingFields.filter(
      (_: any, i: number) => i !== index
    );
    console.debug("New billing fields after removal:", newBillingFields);
    setBillingFields(newBillingFields);
  };

  if (
    billingCodeExtra.status === "completed" &&
    !billingCodeExtra?.data?.billing_codes
  ) {
    return <></>;
  }

  return (
    <>
      <SlideSidePane
        open={isAccuracyReviewOpen}
        onClose={() => setIsAccuracyReviewOpen(false)}
        title="Billing Feedback"
        dismissableCards={[
          {
            headerTitle:
              accuracyFeedback === true
                ? "We're getting better!"
                : "Help us learn!",
            bodyText: [
              "JotPsych is trying to get better at suggesting billing codes. We'd love your feedback to help us learn and improve.",
              "Please adjust the codes to how you would submit them for this encounter, and let us know what we might have missed in the comment box.",
            ],
          },
        ]}
      >
        {!feedbackSubmitted ? (
          <>
            {billingFields.map((billingField: any, index: number) => (
              <BillingCodeSection
                key={index}
                index={index}
                billingField={billingField}
                onFieldChange={handleBillingFieldChange}
                onAddDiagnosisCode={handleAddDiagnosisCode}
                onRemoveDiagnosisCode={handleRemoveDiagnosisCode}
                onDeleteSection={handleRemoveBillingCodeSection}
              />
            ))}
            <Box sx={{ mt: 2 }}>
              <TextField
                label="Comment"
                multiline
                fullWidth
                rows={4}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </Box>
            <RoundedButton
              variant="contained"
              color="primary"
              onClick={handleSubmitFinalFeedback}
              sx={{ mt: 2 }}
            >
              Submit Feedback
            </RoundedButton>
          </>
        ) : (
          <>
            <AnimatedCheckmark />
            <Typography sx={{ mb: 2 }}>
              As you know, we're determined to cut down on your admin time. You
              didn't get into behavioral health to write notes. And you
              definitely didn't get into medicine to nitpick with insurance
              companies.
            </Typography>

            <Typography sx={{ mb: 2 }}>
              Our vision is that you never worry about billing. You just submit
              your billing at the end of the visit, and get promptly paid.
            </Typography>

            <Typography sx={{ mb: 3 }}>
              To make this feature the best it can be, we're inviting Beta
              testers to shape it with us. Select the features you'd like
              JotPsych to develop, and you'll receive early access and a chance
              to provide feedback:
            </Typography>

            <Box sx={{ pl: 3, mb: 3 }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={betaInterest.codeBill}
                      onChange={(e) =>
                        setBetaInterest((prev) => ({
                          ...prev,
                          codeBill: e.target.checked,
                        }))
                      }
                    />
                  }
                  label="JotPsych codes bills (we'll learn from your edits to improve continuously)"
                  sx={{ mb: 1 }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={betaInterest.submitClaims}
                      onChange={(e) =>
                        setBetaInterest((prev) => ({
                          ...prev,
                          submitClaims: e.target.checked,
                        }))
                      }
                    />
                  }
                  label="JotPsych submits claims directly to insurance for reimbursement"
                  sx={{ mb: 1 }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={betaInterest.submitSuperbills}
                      onChange={(e) =>
                        setBetaInterest((prev) => ({
                          ...prev,
                          submitSuperbills: e.target.checked,
                        }))
                      }
                    />
                  }
                  label="JotPsych sends superbills to clients for payment"
                  sx={{ mb: 1 }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={betaInterest.processPayments}
                      onChange={(e) =>
                        setBetaInterest((prev) => ({
                          ...prev,
                          processPayments: e.target.checked,
                        }))
                      }
                    />
                  }
                  label="JotPsych tracks and processes payments directly to your bank account"
                  sx={{ mb: 1 }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={betaInterest.appealClaims}
                      onChange={(e) =>
                        setBetaInterest((prev) => ({
                          ...prev,
                          appealClaims: e.target.checked,
                        }))
                      }
                    />
                  }
                  label="JotPsych appeals denied claims on your behalf"
                  sx={{ mb: 1 }}
                />
              </FormGroup>
            </Box>

            <Typography sx={{ mb: 2, fontWeight: 500 }}>
              Best phone number at which to reach you (via text):
            </Typography>
            <TextField
              fullWidth
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              sx={{ mb: 3 }}
              placeholder="(123) 456-7890"
            />

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <RoundedButton
                variant="contained"
                color="primary"
                onClick={handleBetaInterestSubmit}
              >
                Submit
              </RoundedButton>
            </Box>
          </>
        )}
      </SlideSidePane>
      <Box onMouseEnter={handleMouseEnter}>
        <ContentCard
          sx={{
            minHeight: "275px",
            position: "relative",
          }}
          icon={
            billingCodeExtra.status === "processing" ? (
              <CircularProgress size={20} sx={{ mr: 2 }} />
            ) : (
              <Paid fontSize="medium" sx={{ mr: 2 }} />
            )
          }
          title="Bill with JotPsych (Beta)"
          content={
            billingCodeExtra.status === "completed" ? (
              <Box
                sx={{
                  flexGrow: 1,
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <ReceiptCard ref={receiptCardRef} sx={{ mb: 2 }}>
                  {billingCodeExtra.data.billing_codes.billing_codes.map(
                    (billing) => (
                      <Box key={billing.code} sx={{ mb: 0.5 }}>
                        <Tooltip
                          title={billing.evidence}
                          arrow
                          placement="left"
                          sx={{ typography: "caption", fontSize: "0.8rem" }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              fontWeight: "bold",
                              fontSize: "0.8rem",
                              "&:hover": {
                                textDecoration: "underline",
                              },
                            }}
                          >
                            {billing.code}: {billing.description}
                          </Typography>
                        </Tooltip>
                        {billingCodeExtra.data.diagnosis_codes.billing_code_mapping
                          .filter(
                            (mapping) => mapping.cpt_code === billing.code
                          )
                          .flatMap((mapping) =>
                            mapping.supported_diagnosis_codes.map(
                              (diagnosis) => (
                                <Box key={diagnosis.code} sx={{ pl: 1 }}>
                                  <Tooltip
                                    title={diagnosis.evidence}
                                    arrow
                                    placement="left"
                                    sx={{
                                      typography: "caption",
                                      fontSize: "0.8rem",
                                    }}
                                  >
                                    <Typography
                                      variant="caption"
                                      sx={{
                                        fontSize: "0.7rem",
                                        "&:hover": {
                                          textDecoration: "underline",
                                        },
                                      }}
                                    >
                                      {diagnosis.code}: {diagnosis.description}
                                    </Typography>
                                  </Tooltip>
                                </Box>
                              )
                            )
                          )}
                      </Box>
                    )
                  )}
                  {isOverflowing && (
                    <>
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          right: 0,
                          height: "50px",
                          background: `linear-gradient(to bottom, rgba(255, 255, 255, .8), ${theme.palette.background.paper})`,
                          zIndex: 1, // Ensure the gradient is below the button
                        }}
                      />
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          right: 0,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "70px",
                          zIndex: 2, // Ensure the button is above the gradient
                        }}
                      ></Box>
                    </>
                  )}
                </ReceiptCard>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 1,
                    mt: 2,
                  }}
                >
                  {!feedbackSubmitted ? (
                    <>
                      <Typography variant="body2" sx={{ fontWeight: 500 }}>
                        Are these codes accurate?
                      </Typography>
                      <Box sx={{ display: "flex", gap: 2 }}>
                        <Button
                          variant="outlined"
                          startIcon={<ThumbUp />}
                          onClick={() => handleSubmitAccuracyFeedback(true)}
                          sx={{ minWidth: 120 }}
                        >
                          Yes
                        </Button>
                        <Button
                          variant="outlined"
                          startIcon={<ThumbDown />}
                          onClick={() => handleSubmitAccuracyFeedback(false)}
                          sx={{ minWidth: 120 }}
                          color="error"
                        >
                          No
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <Typography>Thanks for your feedback!</Typography>
                  )}
                </Box>
                <Modal
                  open={showResubmitConfirmation}
                  onClose={() => setShowResubmitConfirmation(false)}
                  aria-labelledby="resubmit-confirmation-modal"
                  aria-describedby="resubmit-confirmation-description"
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      bgcolor: "background.paper",
                      boxShadow: 24,
                      p: 4,
                      borderRadius: 2,
                    }}
                  >
                    <Typography
                      id="resubmit-confirmation-modal"
                      variant="h6"
                      component="h2"
                      gutterBottom
                    >
                      Confirm Resubmission
                    </Typography>
                    <Typography
                      id="resubmit-confirmation-description"
                      sx={{ mt: 2 }}
                    >
                      Are you sure you want to resubmit? This will delete your
                      previous claim.
                    </Typography>
                    <Box
                      sx={{
                        mt: 3,
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        onClick={() => setShowResubmitConfirmation(false)}
                        sx={{ mr: 2 }}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={handleResubmitConfirm}
                        variant="contained"
                        color="primary"
                      >
                        Confirm
                      </Button>
                    </Box>
                  </Box>
                </Modal>
              </Box>
            ) : null
          }
        />
      </Box>
    </>
  );
};

export default TestGatherBillingSidebar;
